
* {
  font-family: sans-serif;
}
body {
  background:#fafafa;
height:100vh;
}


.task {
  line-height:1.25em;
  font-size:14px;
  overflow:auto;
  padding:0rem 1rem 0rem 3rem;
  margin: 0rem 0 0rem -3rem;
  break-inside:avoid;
  max-height:5em;
  margin-bottom:1.25em;
x-webkit-mask-image: -webkit-gradient(linear, left 75%, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)))}

/*.task > * {
  outline:1px solid green;
}
.task {
  outline:1px solid red;
}*/

.task:hover{
  /*background:white;*/
  /*box-shadow: 0 1px 3px rgba(0, 0, 0, 0.10), 0 3px 6px rgba(0, 0, 0, 0.05);*/
}

.groupTitle {
  color: var(--color, rgba(0,0,0,0.3));
  /*border-top: 2px solid var(--color, black);*/
  break-after: avoid;
  margin: 1rem 0 0 0rem;
  text-transform: uppercase;
  font-size:9px;
  font-weight:bold;
}
.groupTitle {break-after: avoid}


.tasksContainer {
  column-width:16rem;
  position:absolute;
  bottom:0;
  height:80vh;
  overflow:visible;
  padding-top:10vh;
  padding-left:5vw;
  padding-bottom:10vh;
}
/*  display:flex;
  flex-direction: row;
  padding:1em;
*/

.taskList {

  padding:0 2em;
  margin-bottom:4em;
}

.task.subtask {
  margin-left:0rem;
}

.groupTitle.overdue,
.task.overdue {
  --color: #F05533;
}

.task.complete .title {
  text-decoration: line-through;
}

.checkbox {
  float:left;
  margin-left: -2rem;
  width:1rem;
  height:1rem;
  display:flex;
  align-items: center;
  justify-content: center;
}

.task input[type=checkbox] {
    -webkit-appearance: none;
    width: 0em;
    height: 0em;
    border: 2px solid var(--color, black);
    transition:height 200ms, width 200ms, margin 200ms, border-radius 200ms, background-color, border;
    box-sizing: border-box;
    padding:0;
}

.task input[type=checkbox]:focus {
  /*outline: none;*/
  border: 2px solid blue;
}


.task:hover input[type=checkbox] {
      height:1em; width:1em;

    border: 2px solid var(--color, black);
}

input[type="checkbox"]:checked{
  background-color: var(--color, black);
  opacity:0.5;
}

a {
  color:#2d2d88;
  word-break: break-word;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

.links a {
  display:block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

}

a.email:hover::before {
  content:' EMAIL ';
  display:inline-block;
  float:RIGHT;
font-size:9px;
background:#eee;
}


.notes {
  color:rgba(0,0,0,0.54);
  overflow:hidden;
}

.taskList h1 {
  break-after: avoid;
  font-size:16px;
  text-align:left;
  border-top:4px solid black;
  padding-top:0.5em;
}
.due {
  break-after: avoid;
  color:var(--color, rgba(0,0,0,0.54));
  margin-right:0.5em;
  text-transform: uppercase;
  font-size:9px;
  font-weight:bold;
  line-height:1.25rem;
}
/*.task:not(.overdue) .due{
float:right;
}*/