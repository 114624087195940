.App {
  
}


.modal {
  margin:auto;
  width:300px;
  margin-top:10vh;
  padding:2em;
  background:white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.10), 0 3px 6px rgba(0, 0, 0, 0.05);
  font-size:large;
}

.modal button {
  background:#2d2d2d;
  color:white;
  border-radius:3em;
  border:none;
  padding:1em 2em;
  font-weight:bold;
  text-transform:uppercase;
}


.toolbar {
	text-align:right;
  position:fixed;
  top:0;
  right:0;
}



button.signOut {
	display:inline-block;
  margin:1em;
  padding:.5em 1em;
  border:none;
  background:#fafafa;
  border-radius:2em;
}

button.signOut:hover {
  background:black;
  color:white;
}